import "antd/dist/reset.css";
import "dayjs/locale/de";
import "./parse.config";

if (!window.localStorage.getItem("opendash:language")) {
  window.localStorage.setItem("opendash:language", '"de"');
}

import { init, StorageAdapterLS } from "@opendash/core";
import { registerIconPack } from "@opendash/icons";
import { GeoPlugin } from "@opendash/plugin-geo";
import { GeoPluginMapLibre } from "@opendash/plugin-geo-maplibre";
import { $parse, ParsePlugin } from "@opendash/plugin-parse";
import { AdminRoute } from "./ride-sharing-admin";
import { AppRoute } from "./ride-sharing-app";
import dayjs from "dayjs";

window.onerror = function (message, file, line, col, error) {
  return false;
};
window.addEventListener("error", function (e) {
  return false;
});

window.addEventListener("unhandledrejection", function (e) {});

init("opendash", async (factory) => {
  // @ts-ignore
  registerIconPack(await import("@opendash/icons/dist/fa-light.json"));
  // @ts-ignore
  registerIconPack(await import("@opendash/icons/dist/ai-outlined.json"));

  factory.ui.disableHeader();
  // factory.ui.disableFooter();

  factory.registerServiceWorker();

  // factory.ui.setAuthLoginForm(AuthLoginForm);
  // factory.ui.setAuthSignupForm(AuthSignupForm);

  factory.registerDeviceStorageAdapter(new StorageAdapterLS());
  // Set Dates to German default language
  dayjs.locale("de");

  await factory.use(new ParsePlugin());
  await factory.use(new GeoPlugin());
  await factory.use(new GeoPluginMapLibre());
  await $parse.ui.setClassConfig({
    className: "_User",
    titleFields: ["username"],
    displayFields: [
      "name",
      "username",
      "email",
      "emailVerified",
      "tenant",
      "tenantVerified",
      "tenantAdmin",
      "tenantBanned",
      "updatedAt",
    ],
    createFields: [
      "name",
      "username",
      "email",
      "password",
      "tenant",
      "tenantVerified",
      "tenantAdmin",
      "tenantBanned",
      "emailVerified",
    ],
    editFields: [
      "name",
      "username",
      "email",
      "password",
      "tenant",
      "tenantVerified",
      "tenantAdmin",
      "tenantBanned",
      "emailVerified",
    ],
  });

  await $parse.ui.setDefaultView("_User", {
    type: "table",
  });

  await $parse.ui.setClassConfig({
    className: "OD3_Tenant",

    titleFields: ["label"],
    displayFields: ["label"],
    createFields: ["label"],
    editFields: ["label"],
  });

  await $parse.ui.setClassConfig({
    className: "RideSharingPlace",
    titleFields: ["type", "name"],

    displayFields: [
      "type",
      "name",
      "icon",
      "location",
      "enabled",
      "highlight",
      "osm",
    ],
    createFields: ["type", "name", "icon", "location", "enabled", "highlight"],
    editFields: ["type", "name", "icon", "location", "enabled", "highlight"],
  });
  $parse.ui.setDefaultView("RideSharingPlace", "table");

  await $parse.ui.setDefaultView("RideSharingPlace", {
    type: "table",
  });

  await $parse.ui.setClassConfig({
    className: "RideSharingTaxiInfo",
    titleFields: ["name"],
    displayFields: ["name", "phone", "website", "location", "radius"],
    createFields: ["name", "phone", "website", "location", "radius"],
    editFields: ["name", "phone", "website", "location", "radius"],
  });
  $parse.ui.setDefaultView("RideSharingTaxiInfo", "table");

  await $parse.ui.setDefaultView("RideSharingTaxiInfo", {
    type: "table",
  });

  await $parse.ui.setClassConfig({
    className: "OD3_NavigationGroup",
    titleFields: ["label"],
    displayFields: ["icon", "label", "order", "tenant"],
    createFields: ["icon", "label", "order", "tenant"],
    editFields: ["icon", "label", "order", "tenant"],
  });

  await $parse.ui.setClassConfig({
    className: "OD3_NavigationItem",
    titleFields: ["label"],
    displayFields: [
      "group",
      "place",
      "label",
      "link",
      "activeCondition",
      "routeCondition",
      "order",
      "icon",
      "color",
      "tenant",
    ],
    createFields: [
      "group",
      "place",
      "label",
      "link",
      "activeCondition",
      "routeCondition",
      "order",
      "icon",
      "color",
      "tenant",
    ],
    editFields: [
      "group",
      "place",
      "label",
      "link",
      "activeCondition",
      "routeCondition",
      "order",
      "icon",
      "color",
      "tenant",
    ],
  });

  // factory.registerLanguage("en", "English");
  factory.registerLanguage("de", "Deutsch");
  factory.registerLanguage("de_sie", "Deutsch (Sie)", "de", true);
  factory.registerLanguage(
    "de_supported",
    "Unterstützte Kommunikation",
    "de_sie"
  );
  // factory.registerLanguage(
  //   "de_accessible",
  //   "Reduzierte Oberfläche",
  //   "de_supported"
  // );

  factory.registerTranslationResolver(
    "de",
    "opendash",
    async () => await import("./translations/de/opendash")
  );

  factory.registerAntDesignTranslation("de", () =>
    import("antd/lib/locale/de_DE")
  );

  factory.registerAntDesignTranslation("em", () =>
    import("antd/lib/locale/de_DE")
  );

  factory.registerTranslationResolver(
    "de",
    "rs",
    async () => await import("./translations/de/ride-sharing")
  );

  factory.registerTranslationResolver(
    "de",
    "parse-custom",
    async (language) => await import("./translations/de/parse-custom.json")
  );

  factory.registerTranslationResolver(
    "de_sie",
    "opendash",
    async () => await import("./translations/de_sie/opendash")
  );

  factory.registerTranslationResolver(
    "de_sie",
    "rs",
    async () => await import("./translations/de_sie/ride-sharing")
  );

  // Routing

  factory.registerRoute({
    path: "/",
    redirectPath: "/ride-sharing/home",
  });

  factory.registerRoute({
    path: "/admin",
    redirectPath: "/admin/ride-sharing/users/unverified",
  });

  factory.registerRoute({
    path: "/ride-sharing/*",
    componentSync: AppRoute,
  });

  factory.registerRoute({
    path: "/admin/ride-sharing/*",
    componentSync: AdminRoute,
    permission: "parse-admin",
  });
}).then(async (app) => {
  console.log("init open.DASH");
});
