 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { Capacitor } from "@capacitor/core";
import Parse from "parse";

Parse.initialize("kommuter");
Parse.serverURL = getBackend();
Parse.enableLocalDatastore();

function getBackend() {
  switch (Capacitor.getPlatform()) {
    case "web":
      return getBackendUrl(_optionalChain([window, 'optionalAccess', _ => _.location, 'optionalAccess', _2 => _2.hostname]));

    case "android":
    case "ios":
      return getBackendUrl("production");
  }
}

function getBackendUrl(key) {
  switch (key) {
    case "127.0.0.1":
      //return "http://localhost:8080/parse";
      return "https://parse-kommuter-production.apps.openinc.dev/parse";

    case "dev":
    case "dev.kommuter.de":
      return "https://parse-kommuter-dev.apps.openinc.dev/parse";

    case "demo":
    case "demo.kommuter.de":
      return "https://parse-kommuter-demo.apps.openinc.dev/parse";

    case "omi":
    case "omi.kommuter.de":
      return "https://parse-kommuter-omi.apps.openinc.dev/parse";

    case "evemo-dev":
    case "kommuter.evemo.dev":
      return "https://parse-kommuter.evemo.dev/parse";

    //case "localhost":
    case "caritas":
    case "caritas.kommuter.de":
    case "app.mobil-mit-mia.de":
      return "https://parse-kommuter-caritas.apps.openinc.dev/parse";

    case "localhost":
    case "production":
    case "app.kommuter.de":
    default:
      return "https://parse-kommuter-production.apps.openinc.dev/parse";
  }
}
